import React, { useMemo } from "react";
import { IStorefrontViewProps } from "@core-shop/components";
import { getUrlFromAssetDestination } from "@shared/utils";
import classNames from "classnames";
import { Department, HorizontalScroll } from "@shared-tailwind/atoms";

export interface IDeparmentsProps {
  assets: IStorefrontViewProps["collections"][0]["assets"];
  isGrid?: boolean;
}

const GridView: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="grid gap-2 grid-cols-3">{children}</div>
);

export const Departments: React.FC<IDeparmentsProps> = ({ assets, isGrid }) => {
  const Wrapper = useMemo(() => (isGrid ? GridView : HorizontalScroll), [isGrid]);

  return (
    <Wrapper>
      {assets.map(({ title, asset }) => {
        return (
          <Department
            className={classNames(isGrid ? "w-full" : "w-[136px]")}
            href={getUrlFromAssetDestination(asset?.destination ?? "").toString()}
            imageSrc={asset?.small ?? ""}
            key={asset?.small}
            name={title}
          />
        );
      })}
    </Wrapper>
  );
};
