import React, { useMemo } from "react";
import { ICollectionViewProps, IStorefrontViewProps } from "@core-shop/components";
import { pluralize, routes } from "@shared/utils";
import { LinkBlock } from "@shared-tailwind/atoms";
import { BlocksCarousel } from "@shared-tailwind/molecules";

export interface ICarouselProps {
  links: IStorefrontViewProps["collections"][0]["links"] | ICollectionViewProps["collection"]["links"];
  isGrid?: boolean;
}

const GridView: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="grid gap-4 grid-cols-2 sm:grid-cols-3">{children}</div>
);

export const Carousel: React.FC<ICarouselProps> = ({ links, isGrid }) => {
  const Wrapper = useMemo(() => (isGrid ? GridView : BlocksCarousel), [isGrid]);
  return (
    <Wrapper rows={2}>
      {links.map(({ giveSentence, id: linkId, images, name, giftcardType, previewInfo }) => {
        const topDeal = previewInfo.topPromotionInfo?.link;

        let promotionsText;

        if (topDeal?.giveSentence) promotionsText = topDeal.giveSentence;
        if (previewInfo.promotionsCount - 1 && promotionsText)
          promotionsText += ` + ${pluralize(" deal", previewInfo.promotionsCount - 1)}`;

        return (
          <LinkBlock
            brandName={name}
            givesTitle={giveSentence}
            href={routes.shopcloud.linkModal(linkId)}
            imageSrc={images.image ?? ""}
            isRounded={giftcardType === "Gift Card"}
            key={linkId}
            promotionText={promotionsText}
          />
        );
      })}
    </Wrapper>
  );
};
