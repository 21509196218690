import React from "react";
import classNames from "classnames";
import { HorizontalScroll } from "@shared-tailwind/atoms";

interface IProps {
  bgImage?: string;
  children?: React.ReactNode;
  className?: string;
  scrollContainerClassName?: string;
  title?: string;
}

export const FeaturedCarousel: React.FC<IProps> = ({
  children,
  className,
  scrollContainerClassName,
  bgImage,
  title,
}) => {
  return (
    <div
      className={classNames(
        "w-full overflow-x-hidden  pl-4 pb-4 md:pb-9",
        !bgImage && "bg-primary-50",
        className
      )}
      style={{ background: bgImage ? `no-repeat url(${bgImage}) center` : "", backgroundSize: "100%" }}
    >
      <h3 className="w-full text-center text-xl md:text-3xl py-16">{title}</h3>
      <HorizontalScroll hasCenteredItems className={scrollContainerClassName}>
        {children}
      </HorizontalScroll>
    </div>
  );
};
