import React, { ReactNode } from "react";
import classNames from "classnames";
import { ChatButton } from "../ChatButton/ChatButton";
import { Image } from "../Image/Image";
import { InternalLink } from "../InternalLink/InternalLink";

interface IProps {
  brandName: string;
  givesTitle: string;
  imageSrc: string;
  bgColor?: string;
  className?: string;
  givesSubTitle?: string;
  href?: string | URL;
  isFeatured?: boolean;
  isRounded?: boolean;
  promotionText?: ReactNode;
}

export const LinkBlock: React.FC<IProps> = ({
  className,
  imageSrc,
  brandName,
  givesSubTitle,
  givesTitle,
  isRounded,
  promotionText,
  isFeatured,
  bgColor,
  href,
}) => {
  return (
    <InternalLink
      shallow
      className={classNames(
        "bg-white",
        isFeatured ? "w-[230px] text-left" : "w-[155px] text-center",
        className
      )}
      href={href ?? ""}
    >
      <Image
        alt={brandName}
        className={classNames(isFeatured ? "h-[150px]" : "h-[116px]")}
        imagePositionClassName="object-contain"
        imageProps={{
          className: classNames("h-full w-full", isRounded && "rounded-lg"),
          style: { background: bgColor },
        }}
        src={imageSrc}
      />
      {promotionText && (
        <div
          className={classNames(
            "w-full text-center bg-deals-500 relative -top-5 -mb-5",
            "text-gray-900 text-[11px] h-5 leading-5 uppercase"
          )}
        >
          {promotionText}
        </div>
      )}
      <div className={classNames("text-gray-900 leading-6 p-4", isFeatured && "pr-10")}>
        <p className="w-full text-gray-500 text-xs line-clamp-2 h-8">{brandName}</p>
        <p className="w-full relative">
          <span>{givesTitle}</span>
          {isFeatured && (
            <span className="absolute -right-6 top-0">
              <ChatButton />
            </span>
          )}
        </p>
        {givesSubTitle && <p className="w-full text-xs line-clamp-2">{givesSubTitle}</p>}
      </div>
    </InternalLink>
  );
};
