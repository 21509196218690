import React, { useMemo } from "react";
import { ICollectionViewProps } from "@core-shop/components";
import { CollectionStyle } from "@core-shop/types";
import { routes } from "@shared/utils";
import { InternalLink, Loader } from "@shared-tailwind/atoms";
import { HeadingSection } from "@shared-tailwind/molecules";
import {
  BannerCarousel,
  BrandsCarousel,
  Carousel,
  Featured,
  IBannerCarouselProps,
  IBrandsCarouselProps,
  ICarouselProps,
  IFeaturedProps,
} from "@shared-tailwind/organisms/ShopCollections";
import { Departments, IDeparmentsProps } from "../ShopCollections/Departments";

type TProps = ICarouselProps &
  IBannerCarouselProps &
  IFeaturedProps &
  IDeparmentsProps &
  IBrandsCarouselProps & { isGrid: boolean };

interface IProps extends ICollectionViewProps {
  hasTitle?: boolean;
  isGrid?: boolean;
}

const { CAROUSEL, BANNER_CAROUSEL, DEPARTMENT_GRID, FEATURED, BRAND_CAROUSEL } = CollectionStyle;
const COLLECTIONS_HAS_SEE_ALL = [CAROUSEL, DEPARTMENT_GRID, BRAND_CAROUSEL];
const COLLECTIONS_NO_HEADER = [FEATURED, BANNER_CAROUSEL];

export const ShopCollection: React.FC<IProps> = ({
  collection,
  hasTitle = true,
  collectionQuery,
  isGrid,
  toggleFavoriteMutation,
  shouldShowLikeButton,
}) => {
  const { style, links, name: collectionName, assets, id, brands } = collection ?? {};

  const CollectionComponent = useMemo(() => {
    let Component: React.FC<TProps> | null = null;

    switch (style) {
      case CAROUSEL: {
        if (!links?.length) return null;
        Component = (props) => <Carousel {...props} isGrid={isGrid} />;
        break;
      }
      case BANNER_CAROUSEL: {
        if (!assets?.length) return null;
        Component = BannerCarousel;
        break;
      }
      case FEATURED: {
        if (!links) return null;
        Component = Featured;
        break;
      }
      case DEPARTMENT_GRID: {
        if (!assets?.length) return null;
        Component = (props) => <Departments {...props} isGrid={isGrid} />;
        break;
      }
      case BRAND_CAROUSEL: {
        if (!brands?.length) return null;
        Component = (props) => <BrandsCarousel {...props} isGrid={isGrid} />;
        break;
      }
      default: {
        break;
      }
    }
    return Component;
  }, [assets?.length, brands?.length, isGrid, links, style]);

  if (!style) return null;

  return (
    <div className="w-full mb-10" key={id}>
      {collectionQuery?.isLoading && (
        <div className="w-full py-10 flex justify-center">
          <Loader size={96} />
        </div>
      )}
      {!!CollectionComponent && hasTitle && !COLLECTIONS_NO_HEADER.includes(style) && (
        <HeadingSection
          hasNoBorder
          shouldForceOneLine
          Buttons={
            COLLECTIONS_HAS_SEE_ALL.includes(style) && (
              <InternalLink
                className="text-primary-500 pb-2 whitespace-nowrap"
                href={routes.shopcloud.collection({ id })}
              >
                See All
              </InternalLink>
            )
          }
        >
          {collectionName}
        </HeadingSection>
      )}

      {CollectionComponent && (
        <CollectionComponent
          assets={assets}
          brands={brands}
          isGrid={!!isGrid}
          links={links}
          shouldShowLikeButton={shouldShowLikeButton}
          toggleFavioriteMutation={toggleFavoriteMutation}
        />
      )}
    </div>
  );
};
