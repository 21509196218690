import React from "react";
import { ChatAltIcon } from "@heroicons/react/outline";
import { ButtonSizes } from "@shared-tailwind/types";
import { Button, ButtonVariants } from "../Button/Button";

interface IProps
  extends Omit<React.ComponentProps<typeof Button>, "variant" | "Icon" | "iconClassName" | "size"> {}

export const ChatButton: React.FC<IProps> = ({ ...props }) => {
  return (
    <Button
      {...props}
      resetSizeClasses
      className="px-2 py-1"
      Icon={ChatAltIcon}
      iconClassName="text-primary-500 w-5 h-5"
      size={ButtonSizes.xs}
      variant={ButtonVariants.secondary}
    />
  );
};
