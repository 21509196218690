import React from "react";
import { IStorefrontViewProps } from "@core-shop/components";
import { TToggleFavoriteMutation } from "@core-shop/types";
import { routes } from "@shared/utils";
import { BrandCard } from "@shared-tailwind/atoms";
import { FeaturedCarousel } from "@shared-tailwind/molecules";

export interface IFeaturedProps {
  links: IStorefrontViewProps["collections"][0]["links"];
  toggleFavioriteMutation: TToggleFavoriteMutation;
  collectionName?: string;
}

export const Featured: React.FC<IFeaturedProps> = ({ links, collectionName, toggleFavioriteMutation }) => {
  return (
    <FeaturedCarousel title={collectionName ?? "Featured"}>
      {links.map(({ giveSentence, id: linkId, images, name, defaultBrand }, i) => (
        <BrandCard
          brandId={defaultBrand?.id}
          brandName={name}
          givesTitle={giveSentence}
          href={routes.shopcloud.linkModal(linkId)}
          imageSrc={images.image ?? ""}
          isFeatured={i === 0}
          key={linkId}
          toggleFavioriteMutation={toggleFavioriteMutation}
        />
      ))}
    </FeaturedCarousel>
  );
};
