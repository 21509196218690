import React from "react";
import classNames from "classnames";
import { Image } from "../Image/Image";
import { InternalLink } from "../InternalLink/InternalLink";

interface IProps {
  name: string;
  src: string;
  className?: string;
  href?: string;
}

export const Banner: React.FC<IProps> = ({ src, name, className, href }) => {
  return (
    <InternalLink
      className={classNames("w-full max-h-[400px] block rounded-lg overflow-hidden", className)}
      href={href ?? ""}
    >
      <Image
        alt={name}
        className="w-full h-full"
        imagePositionClassName="object-cover"
        imageProps={{ className: "h-full w-full" }}
        src={src}
      />
    </InternalLink>
  );
};
