import React from "react";
import { TToggleFavoriteMutation } from "@core-shop/types";
import classNames from "classnames";
import { BaseBrandBlock } from "../BaseBrandBlock/BaseBrandBlock";
import { Image } from "../Image/Image";
import { InternalLink } from "../InternalLink/InternalLink";

interface IProps {
  brandName: string;
  givesTitle: string;
  imageSrc: string;
  bgColor?: string;
  brandId?: string;
  className?: string;
  href?: URL | string;
  isFeatured?: boolean;
  isLiked?: boolean;
  toggleFavioriteMutation?: TToggleFavoriteMutation;
}

export const BrandCard: React.FC<IProps> = ({
  className,
  imageSrc,
  brandName,
  givesTitle,
  isFeatured,
  isLiked,
  href,
  bgColor,
  brandId,
  toggleFavioriteMutation,
}) => {
  return (
    <div
      className={classNames(
        "rounded-lg border bg-white",
        isFeatured ? "w-[230px] text-left" : "h-[200px] w-[155px] text-center",
        className
      )}
    >
      <InternalLink href={href ?? ""}>
        <Image
          alt={brandName}
          className={classNames(isFeatured ? "h-[170px]" : "h-[116px]")}
          imagePositionClassName="object-contain"
          imageProps={{ className: classNames("h-full w-full rounded-t-lg"), style: { background: bgColor } }}
          src={imageSrc}
        />
      </InternalLink>
      <div className="p-3">
        <BaseBrandBlock
          buttonText="Shop"
          className="w-full"
          giveAmount={{
            giveSentence: (
              <span className={classNames(isFeatured ? "text-lg" : "text-base")}>{givesTitle}</span>
            ),
          }}
          hasButton={false}
          hasLikeButton={isFeatured}
          hasLogo={false}
          id={brandId}
          isLiked={isLiked}
          title={<span className="text-sm text-gray-500 line-clamp-1">{brandName}</span>}
          onToggleFavoriteMutation={toggleFavioriteMutation}
        />
      </div>
    </div>
  );
};
