import { shopcloud } from "./routes";

const destKeys = {
  storefront: "StoreFront::",
  url: "URL::",
  brand: "Brand::",
  link: "Link::",
  collection: "Collection::",
};

export function getUrlFromAssetDestination(dest: string) {
  if (dest.includes(destKeys.storefront))
    return shopcloud.storefront({ id: dest.replace(destKeys.storefront, "") });

  if (dest.includes(destKeys.url)) return dest.replace(destKeys.url, "");

  if (dest.includes(destKeys.brand)) return shopcloud.brandModal(dest.replace(destKeys.brand, ""));

  if (dest.includes(destKeys.link)) return shopcloud.linkModal(dest.replace(destKeys.link, ""));

  if (dest.includes(destKeys.collection))
    return shopcloud.collection({ id: dest.replace(destKeys.collection, "") });

  return dest;
}
