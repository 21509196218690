import React from "react";
import { IStorefrontViewProps } from "@core-shop/components";
import { getUrlFromAssetDestination } from "@shared/utils";
import { Banner, Slider } from "@shared-tailwind/atoms";

export interface IBannerCarouselProps {
  assets: IStorefrontViewProps["collections"][0]["assets"];
}

export const BannerCarousel: React.FC<IBannerCarouselProps> = ({ assets }) => {
  return (
    <Slider>
      {assets.map(({ asset }) => {
        return (
          <Banner
            className="aspect-[12/9]"
            href={getUrlFromAssetDestination(asset?.destination ?? "").toString()}
            key={asset?.small}
            name=""
            src={asset?.small ?? ""}
          />
        );
      })}
    </Slider>
  );
};
