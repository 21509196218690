import React, { useMemo } from "react";
import { ICollectionViewProps, IStorefrontViewProps } from "@core-shop/components";
import { TToggleFavoriteMutation } from "@core-shop/types";
import { routes } from "@shared/utils";
import { BrandBlock } from "@shared-tailwind/atoms";
import { BlocksCarousel } from "@shared-tailwind/molecules";

export interface IBrandsCarouselProps {
  brands: IStorefrontViewProps["collections"][0]["brands"] | ICollectionViewProps["collection"]["brands"];
  toggleFavioriteMutation: TToggleFavoriteMutation;
  isGrid?: boolean;
  shouldShowLikeButton?: boolean;
}

const GridView: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="grid gap-6 grid-cols-1">{children}</div>
);

export const BrandsCarousel: React.FC<IBrandsCarouselProps> = ({
  brands,
  isGrid,
  toggleFavioriteMutation,
  shouldShowLikeButton,
}) => {
  const Wrapper = useMemo(() => (isGrid ? GridView : BlocksCarousel), [isGrid]);
  return (
    <Wrapper rows={3}>
      {brands?.map(({ id, images, maxGiveLink, name, viewerHasFavorited: isLiked }) => (
        <BrandBlock
          bgColor={images.logoBackgroundColor}
          buttonText="Shop"
          buttonUrl={routes.shopcloud.brandModal(id)}
          givesTitle={maxGiveLink?.giveSentence}
          hasLikeButton={shouldShowLikeButton}
          id={id}
          imageSrc={images.invertedLogo || images.logo || ""}
          isLiked={isLiked}
          key={id}
          name={name}
          onToggleFavoriteMutation={toggleFavioriteMutation}
        />
      ))}
    </Wrapper>
  );
};
